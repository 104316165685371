<template>
  <div class="fixed inset-0 overflow-hidden add-new-link">
    <div class="absolute inset-0 overflow-hidden">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <ValidationObserver v-slot="{ handleSubmit, reset }">
        <form @submit.prevent="handleSubmit(add)" @reset.prevent="reset">
          <section class="absolute inset-y-0 right-0 pl-10 max-w-full flex">
            <div class="w-screen max-w-lg">
              <div
                class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl"
              >
                <div
                  class="min-h-0 flex-1 flex flex-col pb-6 overflow-y-scroll"
                >
                  <header class="py-6 px-8 bg-gray-100">
                    <div>
                      <div class="flex justify-between">
                        <h3 class="text-lg leading-6 font-normal text-gray-900">
                          {{ $t("add-new-link") }}
                        </h3>
                      </div>
                      <div
                        class="h-7 flex items-center absolute top-5 right-5"
                        @click="$emit('close')"
                      >
                        <button
                          aria-label="Close panel"
                          class="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150 focus:outline-none"
                        >
                          <!-- Heroicon name: x -->
                          <svg
                            class="h-5 w-5"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6 18L18 6M6 6l12 12"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </header>
                  <div class="relative flex-1 mt-0 sm:p-0 p-6">
                    <!-- Replace with your content -->
                    <div>
                      <div class="sm:px-0 pt-8">
                        <dl>
                          <div class="sm:px-8">
                            <dt
                              class="text-sm leading-5 font-normal sm:col-span-2"
                            >
                              {{ $t("link-name") }}
                              <span class="text-sm text-red-600 h-4">*</span>
                            </dt>
                            <dd
                              class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3"
                            >
                              <div class="sm:col-span-3">
                                <ValidationProvider
                                  v-slot="{ errors }"
                                  name="link_name"
                                  rules="required"
                                >
                                  <input
                                    v-model="form.name"
                                    :placeholder="$t('link-name')"
                                    class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                  />
                                  <span class="text-sm text-red-600 h-4">{{
                                    errors[0]
                                  }}</span>
                                </ValidationProvider>
                              </div>
                            </dd>
                          </div>
                          <div class="sm:px-8 mt-5">
                            <dt
                              class="text-sm leading-5 font-normal sm:col-span-2"
                            >
                              {{ $t("Description") }}
                            </dt>
                            <dd
                              class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3"
                            >
                              <div class="sm:col-span-3">
                                <ValidationProvider
                                  v-slot="{ errors }"
                                  name="description"
                                >
                                  <textarea
                                    v-model="form.description"
                                    :placeholder="$t('Description')"
                                    class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                  ></textarea>
                                  <span class="text-sm text-red-600 h-4">{{
                                    errors[0]
                                  }}</span>
                                </ValidationProvider>
                              </div>
                            </dd>
                          </div>
                          <div class="sm:px-8 mt-5">
                            <dt
                              class="text-sm leading-5 font-normal sm:col-span-2"
                            >
                              {{ $t("Type") }}
                              <span class="text-sm text-red-600 h-4">*</span>
                            </dt>
                            <dd
                              class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3"
                            >
                              <div class="sm:col-span-3">
                                <ValidationProvider
                                  v-slot="{ errors }"
                                  name="type"
                                  rules="required"
                                >
                                  <v-select
                                    v-model="form.target_type"
                                    :options="type"
                                    :placeholder="
                                      $t(
                                        'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                                      )
                                    "
                                    :reduce="(item) => item.value"
                                    label="display_name"
                                    @input="updateCompany(form.target_type)"
                                  >
                                  </v-select>
                                  <span class="text-sm text-red-600 h-4">{{
                                    errors[0]
                                  }}</span>
                                </ValidationProvider>
                              </div>
                            </dd>
                          </div>
                          <div
                            v-if="whitelabel === 'Promena'"
                            class="sm:px-8 mt-5"
                          >
                            <dt
                              class="text-sm leading-5 font-normal sm:col-span-2"
                            >
                              {{ $t("Company") }}
                              <span class="text-sm text-red-600 h-4">*</span>
                            </dt>
                            <dd
                              v-if="get_tracking_links_whitelabel.length > 0"
                              class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3"
                            >
                              <div class="sm:col-span-3">
                                <ValidationProvider
                                  v-slot="{ errors }"
                                  name="type"
                                  rules="required"
                                >
                                  <v-select
                                    v-model="form.whitelabel"
                                    :options="trackingLinkWhitelabel"
                                    :placeholder="
                                      $t(
                                        'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                                      )
                                    "
                                    :reduce="(item) => item.value"
                                    label="display_name"
                                    @input="updateURL(form.whitelabel)"
                                  >
                                  </v-select>
                                  <span class="text-sm text-red-600 h-4">{{
                                    errors[0]
                                  }}</span>
                                </ValidationProvider>
                              </div>
                            </dd>
                          </div>
                          <div class="sm:px-8 mt-5">
                            <div class="flex mb-2">
                              <div class="flex-shrink-0">
                                <!-- Heroicon name: information-circle -->
                                <svg
                                  aria-hidden="true"
                                  class="h-5 w-5 text-blue-400"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    clip-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                    fill-rule="evenodd"
                                  />
                                </svg>
                              </div>
                              <div
                                class="ml-3 flex-1 md:flex md:justify-between"
                              >
                                <p class="text-sm text-blue-700">
                                  {{ $t("enter-full-url-to-the-target-site") }}
                                </p>
                              </div>
                            </div>
                            <dt
                              class="text-sm leading-5 font-normal sm:col-span-2"
                            >
                              {{ $t("target-url") }}
                              <span class="text-sm text-red-600 h-4"></span>
                            </dt>
                            <dd
                              class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3"
                            >
                              <div class="sm:col-span-3">
                                <ValidationProvider
                                  v-slot="{ errors }"
                                  name="target_url"
                                >
                                  <input
                                    v-model="form.url"
                                    :placeholder="$t('target-url')"
                                    class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                    type="url"
                                  />
                                  <span class="text-sm text-red-600 h-4">{{
                                    errors[0]
                                  }}</span>
                                </ValidationProvider>
                              </div>
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-shrink-0 px-8 py-4 space-x-4 flex justify-end">
                  <span class="inline-flex rounded-md shadow-sm ml-3">
                    <button
                      class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                      type="reset"
                      @click="$emit('close')"
                    >
                      {{ $t("Cancel") }}
                    </button>
                  </span>
                  <span class="inline-flex rounded-md shadow-sm">
                    <Button
                      :disabled="isDisabled"
                      class="relative bg-main-button-color inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-normal rounded-md text-white focus:outline-none focus:shadow-outline-indigo"
                      type="submit"
                    >
                      {{ $t("create-link") }}
                    </Button>
                  </span>
                </div>
              </div>
            </div>
          </section>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "AddNewLink",
  data() {
    return {
      form: {},
      isDisabled: false,
      type: [
        { value: 100, display_name: "Sub - IB" },
        { value: 1, display_name: "Client - Live" },
      ],
      whitelabel: process.env.VUE_APP_WHITELABEL,
      trackingLinkWhitelabel: [],
      ib_url: "",
    };
  },
  computed: {
    ...mapGetters(["get_tracking_links_whitelabel"]),
  },
  created() {
    this.$store.dispatch("tracking_links_options");
    if (
      process.env.VUE_APP_WHITELABEL === "GoMarkets" ||
      process.env.VUE_APP_WHITELABEL === "PriceMarkets"
    ) {
      this.type = [
        {
          display_name: "Sub - IB",
          value: 100,
        },
        {
          display_name: "Sub - Partner",
          value: 100,
        },
      ];
    }
    if (
      process.env.VUE_APP_WHITELABEL === "EagleGlobalMarkets" ||
      process.env.VUE_APP_WHITELABEL === "TradeCoreUK"
    ) {
      this.type = [
        { value: 100, display_name: "Sub - IB" },
        { value: 1, display_name: "Client - Live" },
        { value: 2, display_name: "Client - Demo" },
      ];
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.trackingLinkWhitelabel = this.get_tracking_links_whitelabel;
    });
  },
  methods: {
    add() {
      this.isDisabled = true;
      if (this.whitelabel !== "Promena") {
        delete this.form.whitelabel;
      }
      this.$store
        .dispatch("add_tracking_links", this.form)
        .then(() => {
          this.$emit("close");
          this.isDisabled = false;
          this.$notify({
            group: "foo",
            text: `${this.$t("link-added-successfully")}`,
          });
        })
        .catch((err) => {
          this.isDisabled = false;
          this.$notify({
            group: "foo",
            text: `${Object.entries(err.data)[0][1]}`,
            type: "warn",
          });
        });
    },
    // if user selects "type" Sub IB we remove all whitelabels exept Promena
    // also if user selects Client - live, we remove Promena from whitelabel list
    // because client for Promena is ICMCapital
    // also we check if the user already selected that whitelabel
    // and if he did, we clear the form.whitelabel so he need to select again
    updateCompany(type) {
      if (type === 100) {
        this.ib_url = "ib/signup";
        this.trackingLinkWhitelabel = this.get_tracking_links_whitelabel;
        let arr = this.trackingLinkWhitelabel;
        arr = arr.filter(function (obj) {
          return obj.value === "Promena";
        });
        this.trackingLinkWhitelabel = arr;
        if (this.form.whitelabel !== "Promena") {
          this.form.whitelabel = "";
        }
        // this will autopopulate the 'Target URL' input field
        // for Promena we use the updateURL function bellow
        if (this.whitelabel !== "Promena") {
          this.form.url = window.location.origin + "/ib/signup";
        }
      } else if (type === 1) {
        this.ib_url = "";
        this.trackingLinkWhitelabel = this.get_tracking_links_whitelabel;
        let arr = this.trackingLinkWhitelabel;
        arr = arr.filter(function (obj) {
          return obj.value !== "Promena";
        });
        this.trackingLinkWhitelabel = arr;
        if (this.form.whitelabel === "Promena") {
          this.form.whitelabel = "";
        }
        // this will autopopulate the 'Target URL' input field
        if (this.whitelabel !== "Promena") {
          this.form.url = window.location.origin + "/";
        }
      } else {
        this.ib_url = "";
        this.trackingLinkWhitelabel = this.get_tracking_links_whitelabel;
        let arr = this.trackingLinkWhitelabel;
        arr = arr.filter(function (obj) {
          return obj.value !== "Promena";
        });
        this.trackingLinkWhitelabel = arr;
        if (this.form.whitelabel === "Promena") {
          this.form.whitelabel = "";
        }
        // this will autopopulate the 'Target URL' input field
        if (this.whitelabel !== "Promena") {
          this.form.url = window.location.origin + "/demo";
        }
      }
    },
    // this will autopopulate the 'Target URL' input field
    // first we check which whitelabel was selected in dropdown menu
    // then we check if we are on dev, staging, localhost or live
    // and based on that we put the appropriate link in the input
    updateURL(whitelabel) {
      switch (whitelabel) {
        case "ICMAP":
          if (window.location.hostname.includes("dev.")) {
            this.form.url =
              "https://dev.client-portal.icmap.tc.madetight.io/" + this.ib_url;
          } else if (window.location.hostname.includes("staging.")) {
            this.form.url =
              "https://staging.client-portal.icmap.tc.madetight.io/" +
              this.ib_url;
          } else if (window.location.hostname.includes("localhost")) {
            if (process.env.VUE_APP_API_URL.includes("stage.")) {
              this.form.url =
                "https://staging.client-portal.icmap.tc.madetight.io/" +
                this.ib_url;
            } else if (process.env.VUE_APP_API_URL.includes("dev.")) {
              this.form.url =
                "https://dev.client-portal.icmap.tc.madetight.io/" +
                this.ib_url;
            }
          } else {
            this.form.url = "https://clients.icmcapital.my/" + this.ib_url;
          }
          break;
        case "ICMCapital":
          if (window.location.hostname.includes("dev.")) {
            this.form.url =
              "https://dev.client-portal.icmcapital.tc.madetight.io/";
          } else if (window.location.hostname.includes("staging.")) {
            this.form.url =
              "https://staging.client-portal.icmcapital.tc.madetight.io/";
          } else if (window.location.hostname.includes("localhost")) {
            if (process.env.VUE_APP_API_URL.includes("stage.")) {
              this.form.url =
                "https://staging.client-portal.icmcapital.tc.madetight.io/";
            } else if (process.env.VUE_APP_API_URL.includes("dev.")) {
              this.form.url =
                "https://dev.client-portal.icmcapital.tc.madetight.io/";
            }
          } else {
            this.form.url = "https://clients.icmcapital.co.uk/";
          }
          break;
        case "ICMMU":
          if (window.location.hostname.includes("dev.")) {
            this.form.url =
              "https://dev.client-portal.icmmu.tc.madetight.io/" + this.ib_url;
          } else if (window.location.hostname.includes("staging.")) {
            this.form.url =
              "http://staging.client-portal.icmmu.tc.madetight.io/" +
              this.ib_url;
          } else if (window.location.hostname.includes("localhost")) {
            if (process.env.VUE_APP_API_URL.includes("stage.")) {
              this.form.url =
                "http://staging.client-portal.icmmu.tc.madetight.io/" +
                this.ib_url;
            } else if (process.env.VUE_APP_API_URL.includes("dev.")) {
              this.form.url =
                "https://dev.client-portal.icmmu.tc.madetight.io/" +
                this.ib_url;
            }
          } else {
            this.form.url = "https://clients.icmcapital.mu/" + this.ib_url;
          }
          break;
        case "ICMVC":
          if (window.location.hostname.includes("dev.")) {
            this.form.url =
              "https://dev.client-portal.tc.madetight.io/" + this.ib_url;
          } else if (window.location.hostname.includes("staging.")) {
            this.form.url =
              "http://staging.client-portal.tc.madetight.io/" + this.ib_url;
          } else if (window.location.hostname.includes("localhost")) {
            if (process.env.VUE_APP_API_URL.includes("stage.")) {
              this.form.url =
                "http://staging.client-portal.tc.madetight.io/" + this.ib_url;
            } else if (process.env.VUE_APP_API_URL.includes("dev.")) {
              this.form.url =
                "https://dev.client-portal.tc.madetight.io/" + this.ib_url;
            }
          } else {
            this.form.url = "https://clients.icmcapital.com/" + this.ib_url;
          }
          break;
        case "ICMTrader":
          if (window.location.hostname.includes("dev.")) {
            this.form.url =
              "https://dev.client-portal.wlgravity.tc.madetight.io/" +
              this.ib_url;
          } else if (window.location.hostname.includes("staging.")) {
            this.form.url =
              "http://staging.client-portal.wlgravity.tc.madetight.io/" +
              this.ib_url;
          } else if (window.location.hostname.includes("localhost")) {
            if (process.env.VUE_APP_API_URL.includes("stage.")) {
              this.form.url =
                "http://staging.client-portal.wlgravity.tc.madetight.io/" +
                this.ib_url;
            } else if (process.env.VUE_APP_API_URL.includes("dev.")) {
              this.form.url =
                "https://dev.client-portal.wlgravity.tc.madetight.io/" +
                this.ib_url;
            }
          } else {
            this.form.url = "https://clients.icmtrader.com/" + this.ib_url;
          }
          break;
        case "Promena":
          if (window.location.hostname.includes("dev.")) {
            this.form.url =
              "https://dev.client-portal.promena.tc.madetight.io/ib/signup";
          } else if (window.location.hostname.includes("staging.")) {
            this.form.url =
              "http://staging.client-portal.wlgravity.tc.madetight.io/ib/signup";
          } else if (window.location.hostname.includes("localhost")) {
            if (process.env.VUE_APP_API_URL.includes("stage.")) {
              this.form.url =
                "http://staging.client-portal.promena.tc.madetight.io/ib/signup";
            } else if (process.env.VUE_APP_API_URL.includes("dev.")) {
              this.form.url =
                "https://dev.client-portal.promena.tc.madetight.io/ib/signup";
            }
          } else {
            this.form.url = "https://clients.promenamarkets.com/ib/signup";
          }
          break;
        case "ICMMENA":
          if (window.location.hostname.includes("dev.")) {
            this.form.url =
              "https://dev.client-portal.icmmena.tc.madetight.io/ib/signup";
          } else if (window.location.hostname.includes("staging.")) {
            this.form.url =
              "https://staging.client-portal.icmmena.tc.madetight.io/ib/signup";
          } else if (window.location.hostname.includes("localhost")) {
            if (process.env.VUE_APP_API_URL.includes("stage.")) {
              this.form.url =
                "https://staging.client-portal.icmmena.tc.madetight.io/ib/signup";
            } else if (process.env.VUE_APP_API_URL.includes("dev.")) {
              this.form.url =
                "https://dev.client-portal.icmmena.tc.madetight.io/ib/signup";
            }
          } else {
            this.form.url = "https://clients.icmmena.com/ib/signup";
          }
          break;
        default:
          this.form.url = "";
      }
    },
  },
};
</script>