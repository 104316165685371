<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div
    class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
  >
    <div class="flex-1 flex justify-between sm:hidden">
      <a
        class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
        href="#"
      >
        {{ $t("Previous") }}
      </a>
      <a
        class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
        href="#"
      >
        {{ $t("Next") }}
      </a>
    </div>
    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
      <div v-if="!clientPagination">
        <p class="text-sm text-gray-700">
          {{ $t("Showing") }}
          <span class="font-medium">{{ pagination.offset + 1 }}</span>
          {{ $t("to") }}
          <span v-if="this.activePage * 10 > results" class="font-medium">{{
            results
          }}</span>
          <span v-else class="font-medium">{{ this.activePage * 10 }}</span>
          {{ $t("of") }}
          <span class="font-medium">{{ results }}</span>
          {{ $t("results") }}
        </p>
      </div>
      <div v-else>
        <p class="text-sm text-gray-700">
          {{ $t("Showing") }}
          <span v-if="pagination.page === 1" class="font-medium">{{
            pagination.page
          }}</span>
          <span v-else class="font-medium">{{
            pagination.page_size * (pagination.page - 1) + 1
          }}</span>
          {{ $t("to") }}
          <span v-if="this.activePage * 10 > results" class="font-medium">{{
            results
          }}</span>
          <span v-else class="font-medium">{{ this.activePage * 10 }}</span>
          {{ $t("of") }}
          <span class="font-medium">{{ results }}</span>
          {{ $t("results") }}
        </p>
      </div>
      <div>
        <nav
          aria-label="Pagination"
          class="relative z-0 inline-flex shadow-sm -space-x-px"
        >
          <a
            v-if="activePage !== 1"
            class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            @click="setPageData(1)"
          >
            First
          </a>
          <a
            v-if="checkLanguage"
            class="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            @click="previousPage"
          >
            <span class="sr-only">Previous</span>
            <!-- Heroicon name: chevron-left -->
            <svg
              aria-hidden="true"
              class="h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                fill-rule="evenodd"
              />
            </svg>
          </a>
          <!-- Next button for Arab and Persian language-->
          <a
            v-if="!checkLanguage"
            class="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            @click="previousPage"
          >
            <span class="sr-only">Next</span>
            <!-- Heroicon name: chevron-right -->
            <svg
              aria-hidden="true"
              class="h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                fill-rule="evenodd"
              />
            </svg>
          </a>
          <a
            v-for="(index, key) in numberOfPages"
            v-if="Math.abs(index - activePage) < 3"
            :key="key"
            :class="index === activePage ? 'bg-gray-200' : ''"
            class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
            @click="setPageData(index)"
          >
            {{ index }}
          </a>
          <!--          <span class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">-->
          <!--            ...-->
          <!--          </span>-->
          <a
            v-if="checkLanguage"
            class="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            @click="nextPage"
          >
            <span class="sr-only">Next</span>
            <!-- Heroicon name: chevron-right -->
            <svg
              aria-hidden="true"
              class="h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                fill-rule="evenodd"
              />
            </svg>
          </a>
          <!-- Prev button for Arab and Persian language-->
          <a
            v-if="!checkLanguage"
            class="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            @click="nextPage"
          >
            <span class="sr-only">Previous</span>
            <!-- Heroicon name: chevron-left -->
            <svg
              aria-hidden="true"
              class="h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                fill-rule="evenodd"
              />
            </svg>
          </a>
          <a
            v-if="activePage !== numberOfPages"
            class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            @click="setPageData(numberOfPages)"
          >
            Last
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Pagination",
  data() {
    return {
      numberOfPages: Math.ceil(this.results / 10),
      activePage: 1,
    };
  },
  props: {
    results: {
      type: Number,
      required: true,
      default: () => {
        return 0;
      },
    },
    pagination: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    clientPagination: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
  },
  computed: {
    checkLanguage() {
      let lang = this.$store.getters.get_lang;
      if (lang == "ar" || lang == "fa") {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    setPageData(index) {
      this.activePage = index;
      if (this.clientPagination) {
        this.$emit("new", {
          page_size: 10,
          page: index,
        });
      } else {
        this.$emit("new", {
          limit: 10,
          offset: (index - 1) * 10,
        });
      }
    },
    previousPage() {
      if (this.activePage > 1) this.activePage--;
      this.$emit("previous");
    },
    nextPage() {
      if (this.numberOfPages > this.activePage) this.activePage++;
      this.$emit("next");
    },
  },
};
</script>
