<template>
  <div>
    <Header>
      <template v-slot:title>
        {{ $t("tracking-links") }}
      </template>
      <template v-if="get_ib_profile.state === 'Approved'" v-slot:buttons>
        <span class="inline-flex rounded-md shadow-sm ml-3">
          <button
            class="relative inline-flex items-center pr-4 pl-1 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
            type="button"
          >
            <download-csv
              v-if="get_tracking_links.results"
              :data="get_tracking_links.results"
              class="flex text-gray-700"
              name="Tracking Links.csv"
            >
              <svg
                class="w-5 h-5 mr-2 text-gray-700"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </svg>
              {{ $t("export-to-csv") }}
            </download-csv>
          </button>
        </span>
        <span class="inline-flex rounded-md shadow-sm ml-3">
          <button
            class="relative bg-main-button-color inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none focus:shadow-outline-indigo"
            type="button"
            @click="showModal = true"
          >
            <svg
              class="h-5 mr-2 text-white group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </svg>
            <span class="hidden md:flex">
              {{ $t("add-new-link") }}
            </span>
            <span class="md:hidden">
              {{ $t("new") }}
            </span>
          </button>
        </span>
      </template>
    </Header>
    <div
      v-if="get_ib_profile.state === 'Approved'"
      class="bg-white shadow rounded-md min-h-screen m-auto max-w-7xl p-0 pb-16 sm:mt-5 m-5 relative"
    >
      <div>
        <div class="flex flex-col">
          <div class="overflow-x-auto">
            <div class="align-middle inline-block min-w-full">
              <div class="overflow-hidden border-b border-gray-200">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("tracking-link") }}
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("Description") }}
                      </th>
                      <th
                        v-if="whitelabel === 'Promena'"
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("Company") }}
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("Type") }}
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("URL") }}
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("Clicks") }}
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("number-of-registrations") }}
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("number-of-activations") }}
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("deposited-clients") }}
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("total-deposits") }}
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("total-withdrawals") }}
                      </th>
                      <th
                        class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ $t("Commission") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    v-if="get_tracking_links"
                    class="bg-white divide-y divide-gray-200"
                  >
                    <tr
                      v-for="(account, index) in get_tracking_links.results"
                      :key="index"
                    >
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        {{ account.name }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        {{ account.description }}
                      </td>
                      <td
                        v-if="whitelabel === 'Promena'"
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        {{ account.whitelabel }}
                      </td>
                      <td
                        v-if="account.target_type_display === 'IB'"
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        Sub - IB
                      </td>
                      <td
                        v-else
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        {{ account.target_type_display }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        {{ account.register_url }}
                        <a
                          class="font-normal ml-3 cursor-pointer text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                          @click="copyToClipboard(account.register_url)"
                        >
                          {{ $t("copy") }}
                        </a>
                        <div
                          v-tooltip="{
                            content: account.url,
                            trigger: 'hover click focus',
                            autoHide: true,
                          }"
                          class="cursor-pointer"
                        >
                          <a>
                            {{ $t("show-destination") }}
                          </a>
                        </div>
                      </td>
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        {{ account.hits_count }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        {{ account.clients_count }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        {{ account.full_clients_count }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        {{ account.deposited_clients_count }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        <money-format
                          :currency-code="get_profile.profile.currency"
                          :value="account.total_deposit"
                        >
                        </money-format>
                      </td>
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        <money-format
                          :currency-code="get_profile.profile.currency"
                          :value="account.total_withdrawal"
                        >
                        </money-format>
                      </td>
                      <td
                        class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
                      >
                        <money-format
                          :currency-code="get_profile.profile.currency"
                          :value="account.total_commission"
                        >
                        </money-format>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <pagination
        v-if="get_tracking_links.count"
        :pagination="pagination"
        :results="get_tracking_links.count"
        class="absolute bottom-0 w-full"
        @new="newPage"
        @next="nextPage"
        @previous="previousPage"
      ></pagination>
    </div>
    <ib-approval-alert v-if="get_ib_profile.state === 'Pending'" />
    <add-new-link v-if="showModal" @close="closeModal"></add-new-link>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MoneyFormat from "vue-money-format";
import Header from "@/components/Header";
import AddNewLink from "@/views/ib/trading/tracking-links/AddNewLink";
import Pagination from "@/components/Pagination";
import IbApprovalAlert from "@/components/IbApprovalAlert";

export default {
  name: "TrackingLinks",
  components: {
    "money-format": MoneyFormat,
    Header,
    AddNewLink,
    Pagination,
    IbApprovalAlert,
  },
  data() {
    return {
      showModal: false,
      pagination: {
        limit: 10,
        offset: 0,
      },
      whitelabel: process.env.VUE_APP_WHITELABEL,
    };
  },
  computed: {
    ...mapGetters(["get_tracking_links", "get_ib_profile", "get_profile"]),
  },
  created() {
    this.$store.dispatch("tracking_links", this.pagination);
    this.$store.dispatch("ib_profile");
    const body = document.getElementById("body");
    body.classList.add("no-horizontal-scroll");
  },
  beforeDestroy() {
    const body = document.getElementById("body");
    body.classList.remove("no-horizontal-scroll");
  },
  methods: {
    copyToClipboard(data) {
      const t = this.$t.bind(this);
      this.$copyText(data);
      this.$notify({
        group: "foo",
        text: t("Successfully copied to clipboard!"),
      });
    },
    newPage(data) {
      this.pagination = data;
      this.$store.dispatch("tracking_links", this.pagination);
    },
    previousPage() {
      if (this.get_tracking_links.previous !== null)
        this.$store.dispatch(
          "tracking_links_previous",
          this.get_tracking_links.previous
        );
    },
    nextPage() {
      if (this.get_tracking_links.next !== null)
        this.$store.dispatch(
          "tracking_links_previous",
          this.get_tracking_links.next
        );
    },
    closeModal() {
      this.showModal = false;
      this.$store.dispatch("tracking_links", this.pagination);
    },
  },
};
</script>